"use client"
import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import { useLocale } from "next-intl"
import { useState } from "react"
import { Autoplay } from "swiper"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import {
  Container,
  Desktop,
  LeftArrow,
  Mobile,
  RightArrow,
  StartIcon,
  StopSign,
  transitionTime,
  useIsDesktop,
} from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { sharedColors } from "ui/theme/colors"
import { extractImage } from "utils"
import { extractProperty } from "utils/extractProperty"

const StyledContainer = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  /* max-width: 1040px; */
  ${(props) => props.theme.breakpoints.up("md")} {
    border-radius: 16px;
  }
`
const StyledPaginatorContainer = styled.div`
  position: absolute;
  background-color: #fff;
  bottom: 11px;
  left: 8px;
  z-index: 10;
  padding: 3px 8px;
  border-radius: 200px;
  display: flex;

  ${(props) => props.theme.breakpoints.up("md")} {
    top: unset;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 6px;
    border-radius: 200px;
    background-color: white;
  }
`

const StyledBulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 4px;
`
const StyledPlayButton = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 4.923px;
  border-radius: 123.077px;
  background-color: ${sharedColors.white};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  right: 25px;
  ${(props) => props.theme.breakpoints.up("lg")} {
    right: 10px;
  }
  top: 10px;

  cursor: pointer;
  z-index: 30;
`
const StyledBullet = styled.div<{ active: boolean }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 2px;
  cursor: pointer;
  border: ${(props) =>
    props.active ? `1px solid ${props.theme.colors.bulletsBackgoundColor}` : "1px solid black"};
  background-color: ${(props) =>
    props.active ? props.theme.colors.bulletsBackgoundColor : "none"};
`

type Props = {
  data: any
}
function DynamicSlider({ data }: Props) {
  const isDesktop = useIsDesktop()
  const locale = useLocale()
  const [swiperRef, setSwiperRef] = useState<any>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [isPlaying, setIsPlaying] = useState<boolean>(true)
  const slides = extractProperty(data, "slideElements").map((i: any) => {
    const desktopMedia = extractImage(i, "imageOrVideoSelsctor")
    return {
      desktopMedia,
      mobileMedia: extractImage(i, "imageOrVideoSelectorMobaile") || desktopMedia,
    }
  })
  const toggleAutoplay = () => {
    if (swiperRef) {
      if (isPlaying) {
        swiperRef.autoplay.stop()
      } else {
        swiperRef.autoplay.start()
      }
      setIsPlaying(!isPlaying)
    }
  }
  return (
    <Container>
      <StyledContainer>
        <Swiper
          onSwiper={setSwiperRef}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.activeIndex)
          }}
          style={{
            padding: 0,
            borderRadius: isDesktop ? 16 : 0,
            position: "relative",
          }}
          modules={[Autoplay]}
          speed={transitionTime * 1000}
          autoplay={{
            // Configure autoplay options here
            delay: 4000, // Delay between transitions (in ms). For example, 2500ms here.
            disableOnInteraction: false, // Set to false if you want the autoplay to not stop on user interaction
          }}
        >
          {slides.map((item: any, key: number) => (
            <SwiperSlide
              key={key.toString()}
              style={{
                width: "100%",
                height: isDesktop ? "550px" : "460px",
              }}
            >
              <Desktop>
                <ImageWithAlt
                  image={item?.desktopMedia}
                  fill
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </Desktop>
              <Mobile>
                <ImageWithAlt
                  image={item?.mobileMedia}
                  fill
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </Mobile>
            </SwiperSlide>
          ))}
          <StyledPaginatorContainer>
            <Desktop>
              <IconButton
                onClick={() => {
                  swiperRef.slidePrev()
                }}
              >
                {locale === "en" ? <LeftArrow /> : <RightArrow />}
              </IconButton>
            </Desktop>
            <StyledBulletsContainer>
              {slides.map((_: any, index: number) => (
                <StyledBullet
                  key={index.toString()}
                  onClick={() => {
                    swiperRef.slideTo(index)
                  }}
                  active={index === activeIndex}
                />
              ))}
            </StyledBulletsContainer>
            <Desktop>
              <IconButton
                onClick={() => {
                  swiperRef.slideNext()
                }}
              >
                {locale === "en" ? <RightArrow /> : <LeftArrow />}
              </IconButton>
            </Desktop>
          </StyledPaginatorContainer>
        </Swiper>
        <StyledPlayButton
          onClick={toggleAutoplay}
          aria-label={isPlaying ? "stop-playing-button" : "start-playing-button"}
        >
          {isPlaying ? <StopSign /> : <StartIcon />}
        </StyledPlayButton>
      </StyledContainer>
    </Container>
  )
}

export default DynamicSlider
