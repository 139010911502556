import styled from "@emotion/styled"
import { IconButton, Link } from "@mui/material"
import { useState } from "react"
import { CloseCircleIcon, LinkedinIcon, NewText } from "ui"
import { getUmbracoMediaUrl } from "utils"

const StyledMobileContent = styled.div`
  background-color: ${(props) => props.theme.colors.teamContainerBackgroundColor};
  height: 400px;
  position: absolute;
  top: 0;
  z-index: 9;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledIconWrapper = styled.div`
  position: absolute;
  right: 0px;
`

const StyledSlideContainer = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.up("md")} {
    width: 100%;
    height: 400px;
    &:hover {
      .slide-info-box {
        transform: translateY(-270px);
        background-color: ${(props) => props.theme.colors.propBlue};
      }
      button {
        display: none;
      }
      h1 {
        max-width: 100%;
      }
      .expanded {
        opacity: 1;
      }
    }
  }
`

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: white;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`

const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
  z-index: 3;
`

const StyledInfoBox = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 310px;
  left: 0;
  right: 0;
  background-color: transparent;
  button {
    position: absolute;
  }
`
const StyledInfoBoxInnerContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 10px 16px 16px 16px;
`

const StyledWrapperContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  border-radius: 8px;
  top: 0;
  ${(props) => props.theme.breakpoints.up("md")} {
  }
`

const StyledInfoTextContainer = styled.div<{ withLimit: boolean }>`
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 10px 0;
  max-height: ${(props) => (props.withLimit ? "250px" : "325px")};
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 90%;
    p {
      margin-top: 0px;
    }
  }
`

const StyledTeamsSlideWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.teamContainerBackgroundColor};
  min-height: 95px;
  height: 100%;
`

type Props = {
  item: any
  followMessage: string
}

function TeamContainerItem({ item, followMessage }: Props) {
  const [showContent, setShowContent] = useState(false)

  return (
    <StyledSlideContainer
      style={{
        background: `url(${getUmbracoMediaUrl(item.teamImage)})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundOrigin: "border-box",
        backgroundPosition: "top center",
      }}
    >
      {showContent && (
        <StyledMobileContent>
          <StyledIconWrapper>
            <IconButton onClick={() => setShowContent(false)}>
              <CloseCircleIcon />
            </IconButton>
          </StyledIconWrapper>
          <StyledInfoTextContainer withLimit={!!item?.linkedin?.[0]?.url}>
            <NewText
              desktopFontSize={14}
              desktopFontWeight="600"
              desktopLineHeight="20px"
              mobileFontSize={16}
              mobileFontWeight="600"
              mobileLineHeight={"17px"}
              component="h1"
              color={"white"}
              style={{
                zIndex: 1,
                position: "relative",
              }}
            >
              {item.teamName}
            </NewText>
            <NewText
              desktopFontSize={16}
              desktopFontWeight="600"
              desktopLineHeight="22px"
              mobileFontSize={14}
              mobileFontWeight="400"
              mobileLineHeight={"26px"}
              component="h2"
              color={"white"}
              maxWidth={"60%"}
              style={{
                zIndex: 1,
                position: "relative",
              }}
            >
              {item.teamRole}
            </NewText>
            <NewText
              desktopFontSize={16}
              desktopFontWeight="400"
              desktopLineHeight="22px"
              mobileFontSize={14}
              mobileFontWeight="400"
              mobileLineHeight={"28px"}
              component="div"
              marginTop={"8px"}
              color={"white"}
              style={{
                zIndex: 1,
                position: "relative",
              }}
              dangerouslySetInnerHTML={{
                __html: item.teamDescription,
              }}
            />
          </StyledInfoTextContainer>

          {item?.linkedin?.[0]?.url && (
            <>
              <StyledDivider />
              <StyledLink href={item.linkedin?.[0]?.url || ""}>
                <StyledButtonContainer>
                  <NewText
                    desktopFontSize={16}
                    desktopFontWeight="600"
                    desktopLineHeight="22px"
                    mobileFontSize={16}
                    mobileFontWeight="400"
                    mobileLineHeight={"20px"}
                    component="h1"
                    color={"white"}
                    maxWidth={"80%"}
                    style={{
                      zIndex: 1,
                      position: "relative",
                    }}
                  >
                    {followMessage}
                  </NewText>
                  <LinkedinIcon />
                </StyledButtonContainer>
              </StyledLink>
            </>
          )}
        </StyledMobileContent>
      )}
      <StyledInfoBox className={`slide-info-box`} onClick={() => setShowContent(true)}>
        <StyledInfoBoxInnerContainer>
          <StyledWrapperContainer style={{ resize: "both" }}>
            <StyledTeamsSlideWrapper />
          </StyledWrapperContainer>
          <NewText
            desktopFontSize={14}
            desktopFontWeight="600"
            desktopLineHeight="20px"
            mobileFontSize={16}
            mobileFontWeight="600"
            mobileLineHeight={"17px"}
            component="h1"
            color={"white"}
            style={{
              zIndex: 1,
              position: "relative",
            }}
          >
            {item.teamName}
          </NewText>
          <NewText
            desktopFontSize={16}
            desktopFontWeight="600"
            desktopLineHeight="22px"
            mobileFontSize={14}
            mobileFontWeight="400"
            mobileLineHeight={"20px"}
            component="h2"
            color={"white"}
            style={{
              zIndex: 1,
              position: "relative",
              maxHeight: "44px",
              overflow: "hidden",
              paddingTop: "8px",
            }}
          >
            {item.teamRole}
          </NewText>
        </StyledInfoBoxInnerContainer>
      </StyledInfoBox>
    </StyledSlideContainer>
  )
}

export default TeamContainerItem
