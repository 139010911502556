"use client"
import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import Image from "next/image"
import { useState } from "react"
import { Autoplay, EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/autoplay"
import { Swiper, SwiperSlide } from "swiper/react"
import {
  Container,
  Desktop,
  LeftArrow,
  Mobile,
  RightArrow,
  StartIcon,
  StopSign,
  transitionTime,
  useIsDesktop,
} from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { getUmbracoMediaUrl } from "utils"
import SliderVideoComp from "./SliderVideoComp"

const StyledPaginatorContainer = styled.div`
  position: absolute;
  top: 19px;
  left: 16px;
  z-index: 10;
  background-color: white;
  padding: 10px;
  border-radius: 200px;

  ${(props) => props.theme.breakpoints.up("md")} {
    top: unset;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 6px;
    border-radius: 200px;
  }
`

const StyledBulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6.7px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
  }
  justify-content: flex-start;
  align-items: center;
  margin: 0 4px;
`
const StyledPlayButton = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 4.923px;
  border-radius: 123.077px;
  background-color: ${colors.white};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  right: 25px;
  ${(props) => props.theme.breakpoints.up("lg")} {
    right: 10px;
  }
  top: 10px;

  cursor: pointer;
  z-index: 30;
`
const StyledBullet = styled.div<{ active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 2px;
  cursor: pointer;
  border: ${(props) =>
    props.active
      ? `1.667px solid ${props.theme.colors.bulletsBackgoundColor}`
      : "1.667px solid black"};
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 14px;
    height: 14px;
    border: ${(props) =>
      props.active
        ? `2.333px solid ${props.theme.colors.bulletsBackgoundColor}`
        : "2.333px solid black"};
  }
  background-color: ${(props) =>
    props.active ? props.theme.colors.bulletsBackgoundColor : "none"};
`

const StyledSwiperSlide = styled(SwiperSlide)`
  > div {
    height: 100%;
  }
`

type Props = {
  locale: string
  gallery: Array<{
    imageOrVideoSelectorMobaile?: string
    imageOrVideoSelsctor?: string
  }>
}
const Slider = ({ gallery, locale }: Props) => {
  const [swiperRef, setSwiperRef] = useState<any>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const toggleAutoplay = () => {
    if (swiperRef) {
      if (isPlaying) {
        swiperRef.autoplay.stop()
      } else {
        swiperRef.autoplay.start()
      }
      setIsPlaying(!isPlaying)
    }
  }
  const isDesktop = useIsDesktop()

  return (
    <Container style={{ position: "relative" }}>
      <Swiper
        modules={[Autoplay, EffectFade]}
        onSwiper={setSwiperRef}
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.activeIndex)
        }}
        speed={transitionTime * 1000}
        autoplay={
          isPlaying
            ? {
                delay: 4000,
                disableOnInteraction: false,
              }
            : false
        }
        style={{
          padding: 0,
          borderRadius: 16,
          position: "relative",
        }}
        watchSlidesProgress
        watchOverflow
        effect="fade"
      >
        {gallery.map((item, key: number) => (
          <StyledSwiperSlide
            key={key.toString()}
            style={{
              width: "100%",
              height: isDesktop ? "560px" : "460px",
            }}
          >
            {({ isVisible }) => (
              <>
                <Desktop>
                  {item.imageOrVideoSelsctor &&
                    (!item.imageOrVideoSelsctor?.includes(".mp4") ? (
                      <Image
                        src={getUmbracoMediaUrl(item.imageOrVideoSelsctor)}
                        alt={`product-image-${key + 1}`}
                        fill
                        style={{
                          objectFit: "cover",
                        }}
                        loading={isVisible ? "eager" : "lazy"}
                      />
                    ) : (
                      <SliderVideoComp url={getUmbracoMediaUrl(item.imageOrVideoSelsctor)} />
                    ))}
                </Desktop>
                <Mobile>
                  {item.imageOrVideoSelectorMobaile &&
                    (!item.imageOrVideoSelectorMobaile?.includes(".mp4") ? (
                      <Image
                        src={getUmbracoMediaUrl(item.imageOrVideoSelectorMobaile)}
                        alt={`product-image-${key + 1}`}
                        fill
                        style={{
                          objectFit: "cover",
                        }}
                        loading={isVisible ? "eager" : "lazy"}
                      />
                    ) : (
                      <SliderVideoComp url={getUmbracoMediaUrl(item.imageOrVideoSelectorMobaile)} />
                    ))}
                </Mobile>
              </>
            )}
          </StyledSwiperSlide>
        ))}
        <StyledPaginatorContainer>
          <Desktop>
            <IconButton
              onClick={() => {
                swiperRef.slidePrev()
              }}
            >
              {locale === "he" ? <RightArrow /> : <LeftArrow />}
            </IconButton>
          </Desktop>
          <StyledBulletsContainer>
            {gallery.map((_: any, index: number) => (
              <StyledBullet
                key={index.toString()}
                onClick={() => {
                  swiperRef.slideTo(index)
                }}
                active={index === activeIndex}
              />
            ))}
          </StyledBulletsContainer>
          <Desktop>
            <IconButton
              onClick={() => {
                swiperRef.slideNext()
              }}
            >
              {locale === "he" ? <LeftArrow /> : <RightArrow />}
            </IconButton>
          </Desktop>
        </StyledPaginatorContainer>
      </Swiper>
      <StyledPlayButton
        onClick={toggleAutoplay}
        aria-label={isPlaying ? "stop-playing-button" : "start-playing-button"}
      >
        {isPlaying ? <StopSign /> : <StartIcon />}
      </StyledPlayButton>
    </Container>
  )
}
export default Slider
