"use client"

import styled from "@emotion/styled"
import { Container, Desktop, Mobile, NewText } from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { sharedColors } from "ui/theme/colors"
import { extractImage, extractProperty } from "utils"

const StyledDiv = styled.div<{ desktopPaddingTop?: string; mobilePaddingTop?: string }>`
  padding-top: ${(props) => props.mobilePaddingTop || "32px"};
  ${(props) => props.theme.breakpoints.up("md")} {
    ${(props) => props.desktopPaddingTop || "40px"};
  }
`

const StyledImageWrapp = styled.div`
  position: relative;
  height: 340px;
  width: 100%;
  img {
    border-radius: 16px;
    object-fit: cover;
    object-position: top;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 240px;
  }
`

const StyledOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 26.54%, rgba(0, 0, 0, 0.64) 100%);
`

const StyledTextWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    bottom: unset;
    top: 50%;
    left: 32px;
    transform: translate(0px, -50%);
  }
`

type Props = {
  data: any
  children: any
  desktopPaddingTop?: string
  mobilePaddingTop?: string
}

function ApartmentsBanner({ data, children, desktopPaddingTop, mobilePaddingTop }: Props) {
  const bannerImageForDescktop = extractImage(data, "bannerImageForDescktop")
  const bannerImageForMobile = extractImage(data, "bannerImageForMobile")
  const pageTitle = extractProperty(data, "pageTitle")

  return (
    <Container>
      {children}
      <StyledDiv desktopPaddingTop={desktopPaddingTop} mobilePaddingTop={mobilePaddingTop}>
        <StyledImageWrapp>
          <Desktop>
            <ImageWithAlt image={bannerImageForDescktop} layout="fill" />
          </Desktop>
          <Mobile>
            <ImageWithAlt image={bannerImageForMobile} layout="fill" />
          </Mobile>
          <StyledOverlay />
          <StyledTextWrapper>
            <NewText
              component={"h1"}
              desktopFontSize={40}
              desktopLineHeight={1.1}
              desktopFontWeight={400}
              mobileFontSize={32}
              color={sharedColors.white}
            >
              {pageTitle}
            </NewText>
          </StyledTextWrapper>
        </StyledImageWrapp>
      </StyledDiv>
    </Container>
  )
}
export default ApartmentsBanner
