"use client"

import styled from "@emotion/styled"
import { useTranslations } from "next-intl"
import { usePathname } from "next/navigation"
import { useMemo } from "react"
import { BlockRenderer, Container, CustomBreadcrumbs, FaqNew, ImageTextBlock, TopBanner } from "ui"
import { extractProperty, getBreadCrumbs } from "utils"
import { findProperty } from "utils/findProperty"
import { UndStr } from "utils/projects"
import TeamContainer from "../AbousUs/TeamContainer"
import ApartmentsBanner from "../ApartmentsForRent/ApartmentsBanner"
import MarketingBanner from "../ApartmentsForRent/MarketingBanner"
import DynamicSlider from "../Article/DynamicSlider"
import ContactForm from "../Contact/ContactForm"
import Gallery from "../Projects/Marketing/Gallery"
import Certificates from "../Standards/Certificates"
import ContentText from "./ContentText"
import EligibilityAccordion from "./EligibilityAccordion"
import EligibilityForm from "./EligibilityForm"
import EligibleProjects from "./EligibleProjects"
import HistoryBlock from "./HistoryBlock"
import MainTitle from "./MainTitle"
import TopBannerEligible from "./TopBannerEligible"

const StyledTopDiv = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 98px;
  }
`

type Props = {
  locale: string
  data: any
  companyId?: number
  hideBreadCrumbs?: boolean
  companyName?: string
  messages?: { learnMore: string; followUs: string; learnMoreAbout: string }
}

export const Smp: React.FC<Props> = ({
  data,
  locale,
  hideBreadCrumbs = false,
  companyName,
  companyId,
  messages,
}: Props) => {
  const { selfObject, parents } = getBreadCrumbs(data, locale)
  const pathname = usePathname()
  const differentPathForSlider = "introduction-to-simply"
  const renderSlider = useMemo(() => {
    if (pathname?.includes(differentPathForSlider)) {
      return Gallery
    }
    return DynamicSlider
  }, [pathname])
  const components = {
    topBanner: TopBanner,
    apartmentsBanner: ApartmentsBanner,
    pgeMainTitle: MainTitle,
    contentText: ContentText,
    dynamicSlider: renderSlider,
    historyBlock: HistoryBlock,
    imageTextBlock: ImageTextBlock,
    certificates: Certificates,
    topBanner1: TopBannerEligible,
    eligibleProjectsBlock: EligibleProjects,
    eligibilityInformationItem1: EligibilityAccordion,
    eligibilityFormBlock: EligibilityForm,
    apartmentsSalesSlide: MarketingBanner,
    accordionFaqMain: FaqNew,
    contactHeader1: ContactForm,
    teamContainer: TeamContainer,
  }
  const blocks = findProperty(data.page, "blocks")?.blocks || []
  const hasBanner = blocks?.some(
    (block: any) =>
      block.blockType === "topBanner" ||
      block.blockType === "HeaderWithHeroAndText" ||
      block.blockType === "apartmentsBanner",
  )

  const t = useTranslations("ProjectsPage")
  const formMessages = {
    contactUs: t("contactUs"),
    toProject: t("toProject"),
    priceOffer: t("priceOffer"),
    leaveDetails: t("leaveDetails"),
    close: t("close"),
    virtualTour: t("virtualTour"),
    sales: t("sales"),
    salesCenter: t("salesCenter"),
    forYou: t("forYou"),
    required: t("required"),
    subject: t("subject"),
    fullName: t("fullName"),
    telephone: t("telephone"),
    email: t("email"),
    messageOptional: t("messageOptional"),
    consentMessage: t("consentMessage"),
    privacy: t("privacy"),
    terms: t("terms"),
    send: t("send"),
    groups: t("groups"),
    collaborators: t("collaborators"),
    entr: t("entr"),
    sucessTitle: t("sucessTitle"),
    sucessText: t("sucessText"),
    contactSucessTitle: t("contactSucessTitle"),
    contactSucessText: t("contactSucessText"),
  }

  return (
    <>
      {!hideBreadCrumbs ? (
        <Container>
          <CustomBreadcrumbs current={selfObject} parents={parents} />
        </Container>
      ) : (
        <StyledTopDiv></StyledTopDiv>
      )}
      {blocks?.map((block: any, index: number) => {
        let additionalProps = {}
        if (
          companyName !== "BG Bond" &&
          (block.blockType === "apartmentsBanner" ||
            block.blockType === "apartmentsSalesSlide" ||
            block.blockType === "accordionFaqMain" ||
            block.blockType === "contactHeader1")
        ) {
          return null
        } else if (block.blockType === "numbersComponent1") {
          additionalProps = {
            accentColor: "black",
          }
        } else if (block.blockType === "topBanner") {
          additionalProps = { videoHeight: "272px", imageHeight: "272px" }
        } else if (block.blockType === "teamContainer") {
          additionalProps = { followMessage: messages?.followUs }
        } else if (block.blockType === "contentText" || block.blockType === "pgeMainTitle") {
          additionalProps = {
            noTransition: true,
            hasBanner,
            definedMargin: "112px",
            linkColor: "rgba(91, 117, 83, 1)",
          }
        } else if (block.blockType === "apartmentsSalesSlide") {
          additionalProps = {
            data,
          }
        } else if (block.blockType === "apartmentsBanner" && !hideBreadCrumbs) {
          additionalProps = {
            mobilePaddingTop: "16px",
            desktopPaddingTop: "24px",
          }
        } else if (block.blockType === "contactHeader1") {
          additionalProps = {
            messages: formMessages,
            companyId,
            companyName,
          }
        } else if (block.blockType === "dynamicSlider") {
          const galleryArray = block?.properties?.[0]?.propertyValue?.blocks || []
          const gallery =
            galleryArray?.map((el: any) => ({
              imageOrVideoSelectorMobaile: extractProperty(
                el,
                "imageOrVideoSelectorMobaile",
              ) as UndStr,
              imageOrVideoSelsctor: extractProperty(el, "imageOrVideoSelsctor") as UndStr,
            })) || []
          additionalProps = {
            gallery,
            locale,
          }
        }
        return (
          <BlockRenderer
            key={index}
            blockType={block.blockType}
            blockData={block}
            components={components}
            props={additionalProps}
          />
        )
      })}
    </>
  )
}

export default Smp
