"use client"
import styled from "@emotion/styled"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  ContactUsType,
  getWebContactUsSubjectsCompanyId,
  postWebBmbyLead,
  postWebContactUsSendContactUs,
} from "api"
import React, { LegacyRef, useMemo, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import {
  CheckboxIcon,
  CheckedCheckboxIcon,
  Container,
  Desktop,
  FormSubmitFailIcon,
  FormSubmitSuccessIcon,
  Mobile,
  NewText,
  useIsDesktop,
} from "ui"
import { Culture, submitClick, submitError, submitSuccess } from "utils"

import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { extractProperty, useApi } from "utils"

import { NetworkStatus, useQuery } from "@apollo/client"
import { useTheme } from "@emotion/react"
import GET_PROJECT_DETAILS from "apollo/queries/GET_PROJECT_DETAILS"
import { useLocale, useTranslations } from "next-intl"
import ReCAPTCHA from "react-google-recaptcha"
import { generateSchema, generateSchemaNew } from "utils/Form/FormSchema"
import { ProjectsMessages } from "../Projects/Projects"
import CustomHelperText from "./CustomHelperText"

// eslint-disable-next-line prefer-destructuring
const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY
if (!RECAPTCHA_KEY) {
  throw new Error("Please provide the RECAPTCHA_KEY environment variable.")
}

const StyledContainer = styled.div`
  padding: 16px 0px 0px 0px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 40px 0px 0px 0px;
  }
  input {
    font-size: 16px;
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 16px;
    }
  }
  label {
    font-size: 16px;
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 16px;
    }
  }
`

const StyledNewText = styled(NewText)`
  text-align: left;
  margin: 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    text-align: center;
    margin: 0 auto;
  }
`
const FormContainer = styled.form`
  margin-top: 16px;

  ${(props) => props.theme.breakpoints.up("md")} {
  }
`
const FormTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    gap: 16px;
  }
`
const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const InputLengthContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
`
const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`

const CheckBoxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`

const FormBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 16px;

  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`
const StyledButton = styled.button`
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 200px;
  border: 2px solid #000;
  outline: none;
  min-width: 121px;
  font-size: 18px;
  font-weight: 600;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    border: 2px solid #6f6f6f;
  }
`

const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== "status",
})<{ status: boolean }>`
  padding: 1px 10px 0px 0;
  ${(props) =>
    !props.status
      ? `  rect {
    stroke: red;
  }`
      : ""}
`

type Props = {
  data: any
  companyId?: string
  messages: ProjectsMessages
  companyName?: string
}
const getMainSiteDomain = (): string => {
  const env = process.env.ENV
  if (env === "production") {
    return "https://www.ashtrom.co.il"
  } else if (env === "production_temp") {
    return "https://prod-app-b2b-main.azurewebsites.net"
  } else if (env === "staging") {
    return "https://testmain.ashtrom.co.il"
  } else {
    return "http://localhost:3001"
  }
}

const HelperTextWrapper = styled.div`
  position: absolute;
  bottom: 0;
  font-size: 0.75rem !important;
  transform: translateY(-17px);
`

export const ctaMessage = (string: string) => {
  let newString = string.replace(
    "privacy policy",
    `<a target="_blank" href="${getMainSiteDomain()}/en/content/privacy-policy" style="color: black">privacy policy</a>`,
  )
  newString = newString.replace(
    "מדיניות הפרטיות",
    `<a target="_blank" href="${getMainSiteDomain()}/content/privacy-policy" style="color: black">מדיניות הפרטיות</a>`,
  )
  newString = newString.replace(
    "תנאי השימוש",
    `<a target="_blank" href="${getMainSiteDomain()}/content/terms-of-use" style="color: black">תנאי השימוש</a>`,
  )
  newString = newString.replace(
    "terms of use",
    `<a target="_blank" href="${getMainSiteDomain()}/en/content/terms-of-use" style="color: black">terms of use</a>`,
  )
  return newString
}
const dev = process.env.ENV?.includes("dev")
export const ContactForm: React.FC<Props> = ({ data, companyId, messages, companyName }) => {
  const isDesktop = useIsDesktop()
  const locale = useLocale()

  const culture: Culture = locale === "en" ? "en-us" : "he-il"

  const idParameter = dev ? "5423" : companyId || "5423"
  // eslint-disable-next-line no-unused-vars
  const [res, _, loading] = useApi({
    apiCall: () => getWebContactUsSubjectsCompanyId(idParameter, culture),
  })
  const schema =
    companyName === "Ashtrom Residential for rent" || companyName === "BG Bond"
      ? generateSchemaNew(locale as "en" | "he")
      : generateSchema(locale as "en" | "he")
  const projectsIdArray = () => {
    if (extractProperty(data, "projectForForm")) {
      const projectsToShow = extractProperty(data, "projectForForm")?.map((project: any) => {
        return project.id
      })
      return projectsToShow
    }
  }
  const { data: projects, networkStatus } = useQuery(GET_PROJECT_DETAILS, {
    variables: {
      culture,
      array: projectsIdArray(),
    },
    fetchPolicy: "network-only",
  })
  const projectsAsSubject = useMemo(() => {
    if (networkStatus === NetworkStatus.ready) {
      const projectData = projects?.projects?.nodes?.map((project: any) => {
        return {
          id: project.id,
          projectName: project.namedProperties?.projectTitle?.value,
          leadFormBMBYId: project.namedProperties?.leadFormBMBYId?.value || 0,
          emailForLead: project.namedProperties?.emailForLead?.value || "",
        }
      })
      return projectData
    } else return null
  }, [networkStatus, projects])

  const subTitle = extractProperty(data, "subTitle")

  const formText = extractProperty(data, "formText")
  // const schema = yup.object({
  //   subject: schemaCommon.subject,
  //   fullName: schemaCommon.fullName,
  //   phoneNumber: schemaCommon.allPhoneNumber,
  //   email: schemaCommon.email,
  //   message: schemaCommon.comments,
  // })

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted, isValid },
    watch,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      subject: "",
      fullName: "",
      phone: "",
      email: "",
      message: "",
    },
    resolver: yupResolver(schema),
  })
  const reCaptchaRef = useRef<ReCAPTCHA>()
  const messageValue = watch("message")
  const t = useTranslations("ProjectsPage")
  const tM = useTranslations("SuccessFailBox")
  const [checkedMarketing, setCheckedMarketing] = useState<boolean>(false)
  const [checkedCommercial, setCheckedCommercial] = useState<boolean>(false)

  const [sendSucceded, setSendSucceded] = useState<boolean | null>(null)

  const onSubmit = async (data: any) => {
    reCaptchaRef?.current?.reset()
    const recaptchaResponse = (await reCaptchaRef?.current?.executeAsync()) as string
    if (
      (projectsAsSubject && companyName === "Ashtrom Residential for rent") ||
      companyName === "BG Bond"
    ) {
      const projectData = projectsAsSubject?.find(
        (project: {
          id: number
          leadFormBMBYId: number
          emailForLead: string
          projectName: string
        }) => project.id === parseInt(data.subject),
      )
      const webbmbysenddata = {
        projectId: projectData?.leadFormBMBYId || 0,
        name: data.fullName,
        email: data.email,
        phone: data.phone,
        comments: data.message || "",
        gRecaptchaResponse: recaptchaResponse,
        projectStatus: "",
        allowedMarketing: checkedMarketing,
        allowedSmsAndEmail: checkedCommercial,
        companyId: Number(companyId || 1) || -1,
        uri: window?.location?.href,
        toEmail: projectData?.emailForLead,
      }
      const response = await postWebBmbyLead(webbmbysenddata)
      if (response?.data?.body?.addLeadSucceeded) {
        submitSuccess("שליחה", "השאירו פרטים ונחזור אליכם", true)
        setSendSucceded(true)
        reset()
        clearErrors()
      } else {
        setSendSucceded(false)
        clearErrors()
        submitError("שליחה", messages.forYou, response?.data?.body?.errorMessage)
      }
    } else {
      const requestData = {
        contactUsSubject: data.subject,
        companyId: Number(companyId || 1),
        senderName: data.fullName,
        senderPhone: data.phone,
        senderEmail: data.email,
        content: data.message,
        contactUsType: "General" as ContactUsType,
        freeTextSubject: "",
        allowedMarketing: checkedMarketing,
        allowedSmsAndEmail: checkedCommercial,
        uri: window?.location?.href,
        gRecaptchaResponse: recaptchaResponse,
      }
      postWebContactUsSendContactUs(requestData)
        .then((res: any) => {
          if (res.data.body) {
            submitSuccess("שליחה", "טופס צור קשר", checkedCommercial || checkedMarketing)
            setSendSucceded(true)
          } else {
            setSendSucceded(false)
          }
        })
        .catch((error) => {
          submitError("שליחה", "טופס צור קשר ", error?.message)
          setSendSucceded(false)
        })
        .finally(() => {
          reset()
        })
    }
  }

  const handleButtonPress = () => {
    setSendSucceded(null)
  }

  const handleSendError = () => {
    if (!isValid) {
      if (Object.keys(errors).length > 0) {
        submitError(
          "שליחה",
          "טופס צור קשר ",
          errors?.fullName?.message ||
            errors?.email?.message ||
            errors?.subject?.message ||
            errors?.phone?.message,
        )
      } else {
        submitError("שליחה", "טופס צור קשר ", "subject error")
      }
    }
  }
  const theme = useTheme()
  if (sendSucceded !== null) {
    return (
      <Container>
        <StyledContainer
          style={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "stretch",
            gap: "16px",
            paddingTop: "56px",
            paddingBottom: "62px",
          }}
        >
          {sendSucceded ? (
            <FormSubmitSuccessIcon fill={theme.colors.propBlue} />
          ) : (
            <FormSubmitFailIcon />
          )}
          {/* <NewText
            desktopFontSize={32}
            desktopFontWeight="400"
            desktopLineHeight="36px"
            mobileFontSize={24}
            mobileFontWeight="400"
            mobileLineHeight={"28px"}
            component="h1"
            color={"black"}
          >
            {sendSucceded ? messages.contactSucessTitle : "פנייתך לא נשלחה "}
          </NewText> */}
          <NewText
            desktopFontSize={18}
            desktopFontWeight="400"
            desktopLineHeight="26px"
            mobileFontSize={16}
            mobileFontWeight="400"
            mobileLineHeight={"23px"}
            component="h1"
            color={"black"}
            style={{
              maxWidth: isDesktop ? (locale === "he" ? "360px" : "770px") : "60%",
            }}
            textAlign="center"
          >
            {sendSucceded ? tM("successContactUs") : tM("errorContactUs")}
          </NewText>
          {!sendSucceded && (
            <StyledButton onClick={handleButtonPress}>{tM("tryAgain")}</StyledButton>
          )}
        </StyledContainer>
      </Container>
    )
  }
  return (
    <Container>
      <StyledContainer>
        <StyledNewText
          desktopFontSize={32}
          desktopFontWeight="400"
          desktopLineHeight="36px"
          mobileFontSize={24}
          mobileFontWeight="400"
          mobileLineHeight={"34.56px"}
          component="h1"
          color={"black"}
        >
          {subTitle}
        </StyledNewText>
        <StyledNewText
          desktopFontSize={18}
          desktopFontWeight="400"
          desktopLineHeight="25.92px"
          mobileFontSize={16}
          mobileFontWeight="400"
          mobileLineHeight={"23px"}
          component="div"
          color={"black"}
          marginTop={"8px"}
          maxWidth={"515px"}
          dangerouslySetInnerHTML={{ __html: formText }}
        />
        <StyledNewText
          desktopFontSize={16}
          desktopFontWeight="400"
          desktopLineHeight="23px"
          mobileFontSize={14}
          mobileFontWeight="400"
          mobileLineHeight={"20px"}
          component="h1"
          color={"black"}
        >
          {messages.required}
        </StyledNewText>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <FormTopContainer>
            <Controller
              name="subject"
              control={control}
              render={({ field }) => {
                return (
                  <FormControl
                    fullWidth
                    sx={{
                      "& .MuiInputLabel-shrink": {
                        transform: "translate(8px, 8px)",
                        fontSize: "12px",
                      },
                      position: "relative",
                    }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{
                        top: 0,
                      }}
                    >
                      {messages.subject}
                    </InputLabel>
                    <Select
                      {...field}
                      label="נושא הפניה*"
                      labelId="demo-simple-select-label"
                      variant="filled"
                      error={!!errors.subject}
                      fullWidth
                      sx={{
                        height: "100%",
                        marginBlockEnd: "37px",
                      }}
                      disabled={!!loading}
                      displayEmpty
                      SelectDisplayProps={{ style: { fontSize: 16 } }}
                      inputProps={{
                        style: { fontSize: 16 },
                        "aria-label": "contact-us-subject",
                      }}
                    >
                      {projectsAsSubject
                        ? projectsAsSubject?.map((project: any, i: number) => (
                            <MenuItem
                              key={i}
                              value={project.id}
                              sx={{
                                "&:focus-visible": {
                                  outline: "none",
                                  border: "none",
                                },
                              }}
                            >
                              {project.projectName}
                            </MenuItem>
                          ))
                        : res &&
                          Object.entries(res).map(([key, value]) => (
                            <MenuItem
                              key={key}
                              value={key}
                              sx={{
                                "&:focus-visible": {
                                  outline: "none",
                                  border: "none",
                                },
                              }}
                            >
                              {value}
                            </MenuItem>
                          ))}
                    </Select>
                    <HelperTextWrapper>
                      <CustomHelperText
                        render={isSubmitted}
                        error={!!errors.subject}
                        text={errors.subject?.message}
                      />
                    </HelperTextWrapper>
                  </FormControl>
                )
              }}
            />
            <Controller
              name="fullName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={messages.fullName}
                  type="text"
                  variant="filled"
                  error={!!errors.fullName}
                  helperText={
                    <CustomHelperText
                      render={isSubmitted}
                      error={!!errors.fullName}
                      text={errors.fullName?.message}
                    />
                  }
                  sx={{
                    marginBlockEnd: "37px",
                    "& .MuiFormHelperText-root": {
                      height: "100%",
                      bottom: "-100%",
                    },
                  }}
                  fullWidth
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={messages.telephone}
                  type="text"
                  variant="filled"
                  error={!!errors.phone}
                  helperText={
                    <CustomHelperText
                      render={isSubmitted}
                      error={!!errors.phone}
                      text={errors.phone?.message}
                    />
                  }
                  fullWidth
                  sx={{
                    marginBlockEnd: "37px",
                    "& .MuiFormHelperText-root": {
                      height: "100%",
                      bottom: "-100%",
                    },
                  }}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    label={messages.email}
                    type="text"
                    variant="filled"
                    error={!!errors.email}
                    helperText={
                      <CustomHelperText
                        render={isSubmitted}
                        error={!!errors.email}
                        text={errors.email?.message}
                      />
                    }
                    fullWidth
                    sx={{
                      marginBlockEnd: "37px",
                      "& .MuiFormHelperText-root": {
                        height: "100%",
                        bottom: "-100%",
                      },
                    }}
                  />
                )
              }}
            />
          </FormTopContainer>
          <TextAreaContainer>
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={messages.messageOptional}
                  type="text"
                  variant="filled"
                  error={false}
                  helperText={
                    <CustomHelperText
                      render={isSubmitted}
                      error={false}
                      text={errors.message?.message}
                    />
                  }
                  fullWidth
                  multiline
                  rows={6}
                  inputProps={{
                    maxLength: 500,
                    style: {
                      resize: "vertical",
                    },
                  }}
                  sx={{
                    "& .MuiFormHelperText-root": {
                      left: "40px",
                      bottom: "-22px",
                    },
                    "& .MuiInputLabel-root": {
                      top: "-5px",
                    },
                  }}
                />
              )}
            />
            <InputLengthContainer>
              <NewText
                desktopFontSize={12}
                desktopFontWeight="400"
                desktopLineHeight="17.28px"
                mobileFontSize={12}
                mobileFontWeight="400"
                mobileLineHeight={"17.28px"}
                component="h1"
                color={"black"}
              >
                {messageValue.length}/500
              </NewText>
            </InputLengthContainer>
          </TextAreaContainer>
          <CheckBoxesContainer>
            <StyledCheckboxContainer>
              <StyledCheckbox
                icon={<CheckboxIcon />}
                checkedIcon={<CheckedCheckboxIcon />}
                sx={{
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                }}
                status={true}
                value={checkedMarketing}
                onChange={() => setCheckedMarketing((el) => !el)}
              />

              <div>
                <NewText
                  desktopFontSize={14}
                  desktopFontWeight="400"
                  desktopLineHeight="20px"
                  mobileFontSize={12}
                  mobileFontWeight="400"
                  mobileLineHeight={"17px"}
                  component="p"
                  color={"black"}
                  maxWidth={"750px"}
                  dangerouslySetInnerHTML={{
                    __html: t("marketingMessage"),
                  }}
                ></NewText>
              </div>
            </StyledCheckboxContainer>
            <StyledCheckboxContainer>
              <StyledCheckbox
                icon={<CheckboxIcon />}
                checkedIcon={<CheckedCheckboxIcon />}
                sx={{
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                }}
                status={true}
                value={checkedCommercial}
                onChange={() => setCheckedCommercial((el) => !el)}
              />

              <div>
                <NewText
                  desktopFontSize={14}
                  desktopFontWeight="400"
                  desktopLineHeight="20px"
                  mobileFontSize={12}
                  mobileFontWeight="400"
                  mobileLineHeight={"17px"}
                  component="p"
                  color={"black"}
                  maxWidth={locale === "he" ? "830px" : "750px"}
                  dangerouslySetInnerHTML={{
                    __html: t("commercialMessage"),
                  }}
                ></NewText>
              </div>
            </StyledCheckboxContainer>
          </CheckBoxesContainer>
          <FormBottomContainer>
            <StyledCheckboxContainer>
              <div>
                <NewText
                  desktopFontSize={14}
                  desktopFontWeight="400"
                  desktopLineHeight="20px"
                  mobileFontSize={12}
                  mobileFontWeight="400"
                  mobileLineHeight={"17px"}
                  component="p"
                  color={"black"}
                  maxWidth={"750px"}
                  dangerouslySetInnerHTML={{
                    __html: ctaMessage(t("ctaMessage") || ""),
                  }}
                ></NewText>
              </div>
            </StyledCheckboxContainer>
            <Desktop>
              <Button
                sx={{
                  marginInlineEnd: "auto",
                  width: "153px",
                  fontSize: "18px",
                }}
                variant="outlined"
                size="large"
                type="submit"
                onClick={() => {
                  submitClick(" שליחה", "טופס צור קשר")
                  handleSendError()
                }}
              >
                {locale === "he" ? "שליחה" : "Send"}
              </Button>
            </Desktop>
            <Mobile>
              <Button
                sx={{
                  marginInlineEnd: "auto",
                  marginBlockStart: "40px",
                  width: "153px",
                  fontSize: "16px",
                }}
                variant="outlined"
                size="small"
                type="submit"
                onClick={() => submitClick(" שליחה", "טופס צור קשר")}
              >
                {locale === "he" ? "שליחה" : "Send"}
              </Button>
            </Mobile>
          </FormBottomContainer>
          {RECAPTCHA_KEY && (
            <ReCAPTCHA
              ref={reCaptchaRef as LegacyRef<ReCAPTCHA> | undefined}
              sitekey={RECAPTCHA_KEY}
              size="invisible"
              hl="iw"
              tabIndex={-1}
            />
          )}
        </FormContainer>
      </StyledContainer>
    </Container>
  )
}

export default ContactForm
