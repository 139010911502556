"use client"

import { Container, useIsDesktop } from "ui"
import Slider from "../Slider"

type Props = {
  locale: string
  gallery: Array<{
    imageOrVideoSelectorMobaile?: string
    imageOrVideoSelsctor?: string
  }>
  homepage?: boolean
}

function Gallery({ gallery, locale, homepage }: Props) {
  const isDesktop = useIsDesktop()

  return (
    <Container
      style={{
        paddingTop: isDesktop ? (homepage ? "40px" : "60px") : homepage ? "40px" : "32px",
        paddingBottom: isDesktop ? (homepage ? "40px" : "0") : homepage ? "40px" : "0",
      }}
    >
      {gallery && gallery.length > 0 ? <Slider gallery={gallery} locale={locale} /> : null}
    </Container>
  )
}

export default Gallery
